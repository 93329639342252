import "./Home.css";
import {useEffect, useState} from "react";
import Select from "react-select";
import Modal from "react-modal";
import {addSearch, deleteSearch, downloadFile, getCities, getDepartments, getSearch} from "../request/request";
import {DownloadIcon, MagnifyingGlassIcon, TrashIcon} from "@radix-ui/react-icons";
import toast from "react-hot-toast";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const [allFrance, setAllFrance] = useState(false);
  const [allCities, setAllCities] = useState(false);

  const [query, setQuery] = useState('');
  const [search, setSearch] = useState([]);

  useEffect(() => {
    getDepartments().then(data => {
      setDepartments(data)
    }).catch(err => {
      console.error(err);
    });
  }, []);

  useEffect(() => {
    getCities().then(data => {
      setCities(data)
    }).catch(err => {
      console.error(err);
    });
  }, []);

  useEffect(() => {
    selectedCities.forEach(city => {
      if (!selectedDepartments.map(dep => dep.value).includes(city.codeDepartement)) {
        setSelectedCities(selectedCities.filter(c => c.value !== city.value));
      }
    });
  }, [selectedCities, selectedDepartments]);

  useEffect(() => {
    getSearch().then(data => {
      setSearch(data);
    }).catch(err => {
      console.error(err);
    });
  }, []);

  const downloadSearchFile = async (file) => {
    if (!file) {
      console.error('No file');
      toast.error('Error: No file');
      return;
    }
    file = file.replaceAll('\n', '');
    return await downloadFile(file).then(data => {
      if (!data) {
        console.error('No data');
        return;
      }
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    }).catch(err => {
      console.error(err);
    });
  }

  const addNewSearch = async () => {

    if (!query) {
      console.error('No query');
      toast.error('Veuillez entrer une recherche');
      return;
    }

    let searchCities = [];

    if (allFrance) {
      searchCities = cities.map(city => city.nom);
    } else if (allCities) {
      const citiesList = cities.filter(city => selectedDepartments.map(dep => dep.value).includes(city.codeDepartement))
      searchCities = citiesList.map(city => city.value);
    } else {
      searchCities = selectedCities.map(city => city.value);
    }

    if (searchCities.length === 0) {
      console.error('No cities');
      toast.error('Veuillez sélectionner des villes');
      return;
    }

    const data = {
      query: query,
      cities: searchCities.join(','),
    }
    await addSearch(data).then(data => {
      setIsModalOpen(false);
      toast.success('Recherche ajoutée');
    }).catch(err => {
      toast.error('Error: Impossible d\'ajouter la recherche');
      console.error(err);
    });
  }

  const deleteSearchFile = async (id) => {
    if (!id) {
      console.error('No id');
      toast.error('Error: No id');
      return;
    }
    return await deleteSearch(id).then(data => {
      setSearch(search.filter(s => s.id !== id));
      console.log(data);
    }).catch(err => {
      console.error(err);
    });
  }


  const openModal = () => {
    setIsModalOpen(true);
  }

  const checkboxSetAllFrance = (e) => {
    setAllFrance(e.target.checked);
  }

  const checkboxSetAllCities = (e) => {
    setAllCities(e.target.checked);
  }

  const getSearchDetails = async (details) => {
    return details.split(',').map(detail => {
      const [key, value] = detail.split(':');
      return (
        <div key={key} className="search-details">
          <span className="search-details-key">{key}</span>
          <span className="search-details-value">{value}</span>
        </div>
      )
    });
  }

  const departmentSelector = ({departments, onChange}) => {
    const options = departments.map(dep => ({value: dep.code, label: dep.nom}));
    return (
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        onChange={onChange}
        value={selectedDepartments}
        placeholder="Sélectionnez des départements..."
        className="department-selector"
      />
    );
  };

  const citySelector = ({cities, onChange}) => {
    const departmentsCities = cities.filter(city => selectedDepartments.map(dep => dep.value).includes(city.codeDepartement));
    const options = departmentsCities.map(city => ({
      value: city.nom,
      label: city.nom,
      codeDepartement: city.codeDepartement
    }));

    return (
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        onChange={onChange}
        value={selectedCities}
        placeholder="Sélectionnez des communes..."
        className="city-selector"
      />
    );
  };

  const formatElapsedTime = (startTimestamp, endTimestamp) => {
    if (endTimestamp < startTimestamp) {
      return "..."
    }

    const msDifference = Math.abs(endTimestamp - startTimestamp); // Différence en ms
    const minutes = Math.floor(msDifference / (1000 * 60)) % 60; // Minutes restantes
    const hours = Math.floor(msDifference / (1000 * 60 * 60)) % 24; // Heures restantes
    const days = Math.floor(msDifference / (1000 * 60 * 60 * 24)) % 30; // Jours restants
    const months = Math.floor(msDifference / (1000 * 60 * 60 * 24 * 30)); // Mois entiers

    // Créer un tableau des parties non nulles
    const parts = [];

    if (months > 0) parts.push(`${months}mo`);
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);

    // Joindre les parties avec un espace
    return parts.join(" ");
  }

  const data = {nodes: search};

  return (
    <div className="container">

      {
        search === undefined || search.length === 0 ?
          <div className="no-search">Aucune recherche effectuez, veuillez effectuez une recherche</div> :
          <div className="container-search">
            <table className="container-table">
              <thead className="table-thead">
              <tr className="table-header-row">
                <th style={{whiteSpace: "nowrap"}} className="table-header-cell header-left-round">Nom
                </th>
                <th style={{whiteSpace: "nowrap"}} className="table-header-cell">Date</th>
                <th className="table-header-cell">Temps d'extraction</th>
                <th className="table-header-cell">Nombres de lignes</th>
                <th style={{whiteSpace: "nowrap"}} className="table-header-cell">Statut</th>
                <th style={{whiteSpace: "nowrap"}} className="table-header-cell">Détails</th>
                <th className="table-header-cell">Téléchargement</th>
                <th style={{width: "150px"}} className="table-header-cell header-right-round">
                  <TrashIcon width="21px" height="21px"/>
                </th>
              </tr>
              </thead>
              <tbody className="table-tbody">
              {search === undefined ? null : search.map((item) => (
                <tr key={item.id} className="table-row">
                  <td className="table-cell cell-left-round">{item.query}</td>
                  <td className="table-cell">
                    {new Date(item.updated_at).toLocaleDateString(
                      'fr-FR',
                      {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }
                    )}
                  </td>
                  <td className="table-cell">{item.created_at === item.updated_at ?
                    <div>
                      <div>
                        Temps estimé: {formatElapsedTime(new Date(), new Date(item.time_estimation))}</div>
                    </div> :
                    formatElapsedTime(new Date(item.created_at), new Date(item.updated_at))}</td>
                  <td className="table-cell">{item.line_number}</td>
                  <td
                    className="table-cell">{item.status === 'pending' && item.is_processing ? "processing" : item.status}</td>
                  <td className="table-cell ellipsis">{item.details}</td>
                  <td className="table-cell">
                    <button className="download-button"
                            disabled={item.file_name === null ? true : false}
                            onClick={() => downloadSearchFile(item.file_name)}>
                      <div style={{marginRight: "10px"}}>
                        Télécharger
                      </div>
                      <DownloadIcon width="20px" height="20px"/>
                    </button>
                  </td>
                  <td className="table-cell cell-right-round">
                    <button className="suppr-button" onClick={() => deleteSearchFile(item.id)}>
                      <TrashIcon width="80%" height="80%"/>

                    </button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
      }


      <div className="button-new-extraction" onClick={openModal}>
        <div/>
        <div/>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Créer un nouveau scan"
        style={{
          content: {
            width: '50%',
            height: '50%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
          },
        }}
      >
        <div className="modal-header">
          <div className="modal-header-input">
            <MagnifyingGlassIcon className="modal-header-input-icon" width="30px" height="30px"/>
            <input type="text" name="content" placeholder="Effectuez une recherche"
                   onChange={(e) => setQuery(e.target.value)}/>
          </div>
          <button className="modal-header-submit" type="submit" onClick={addNewSearch}>Rechercher</button>
        </div>

        <div className="checkbox">
          <label className="checkbox-france">
            <input type="checkbox" onChange={checkboxSetAllFrance}/>
            Recherche dans la france entière
          </label>
          <label className="checkbox-cities">
            <input type="checkbox" onChange={checkboxSetAllCities}/>
            Recherche dans toutes les villes
          </label>
        </div>
        <div className="modal-body-container">
          <div className="modal-body-title">
            <label>Départements</label>
            <label>Villes</label>

          </div>
          <div className="modal-body-selector">
            {departmentSelector({departments, onChange: setSelectedDepartments})}
            {citySelector({cities, onChange: setSelectedCities})}
          </div>
        </div>
      </Modal>
    </div>

  );
}

export default Home;